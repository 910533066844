





































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import settings, {
  setPlaybackSpeed,
  setPlaybackVolume
} from '@/store/settings.store'
import bus from '@/service/bus'
import PlayerBarButton from './PlayerBarButton.vue'
import TimePicker from './TimePicker.vue'
import Transcript from '@/classes/transcript.class'
import { timeFromSeconds } from '@/util'

@Component({
  components: {
    PlayerBarButton,
    TimePicker
  }
})
export default class PlayerBar extends Vue {

  @Prop({ default: 64 }) height!: number
  @Prop({ required: true }) transcript!: Transcript

  setPlaybackSpeed = setPlaybackSpeed
  setPlaybackVolume = setPlaybackVolume
  settings = settings
  showTimePicker = false

  cachedVolume = settings.playbackVolume
  cachedSpeed = .5

  seeking = false

  get currentTime() {
    return this.transcript.audio ? this.transcript.audio.currentTime : 0
  }

  rewind(by: number) {
    if (this.transcript.audio !== null) {
      if (by < 0) {
        this.transcript.audio.scrubAudio(Math.ceil(this.transcript.audio.currentTime + by))
      } else {
        this.transcript.audio.scrubAudio(Math.floor(this.transcript.audio.currentTime + by))
      }
    }
  }

  isTimeSpanSelectionEmpty() {
    return this.transcript.isTimeSpanSelectionEmpty()
  }

  toggleVolumeOnOff() {
    if (settings.playbackVolume === 0) {
      setPlaybackVolume(this.cachedVolume)
    } else {
      this.cachedVolume = settings.playbackVolume
      setPlaybackVolume(0)
    }
  }

  toggleSpeed() {
    if (settings.playbackSpeed === 1) {
      setPlaybackSpeed(this.cachedSpeed)
    } else {
      this.cachedSpeed = settings.playbackSpeed
      setPlaybackSpeed(1)
    }
  }

  playPause() {
    // console.log(this.transcript.audio)
    if (this.transcript.audio !== null) {
      if (this.transcript.audio.isPaused === true) {
        const es = this.transcript.getSelectedEvents()
        if (!this.isTimeSpanSelectionEmpty()) {
          console.log('playPause - isTimeSpanSelectionEmpty')
          this.transcript.audio.playRange(this.transcript.uiState.timeSpanSelection.start || 0, this.transcript.uiState.timeSpanSelection.end || 0)
        } else if (es.length > 0) {
          console.log('playPause - length')
          this.transcript.audio.playEvents(es)
        } else {
          console.log('playPause - else')
          this.transcript.audio.playAllFrom(this.currentTime)
        }
      } else {
        this.transcript.audio.pause()
      }
    }
  }

  playEventStart() {
    if (this.transcript.audio) {
      if (this.transcript.audio.isPaused === true) {
        const es = this.transcript.getSelectedEvents()
        if (es.length > 0) {
          this.transcript.audio.playEventsStart(es, 1)
        }
      } else {
        this.transcript.audio.pause()
      }
    }
  }

  playEventEnd() {
    if (this.transcript.audio) {
      if (this.transcript.audio.isPaused === true) {
        const es = this.transcript.getSelectedEvents()
        if (es.length > 0) {
          this.transcript.audio.playEventsEnd(es, 1)
        }
      } else {
        this.transcript.audio.pause()
      }
    }
  }

  updateTimeDisplay(seconds: number, e: HTMLElement|undefined) {
    if (e !== undefined) {
      const newT = timeFromSeconds(seconds, 3)
      e.innerHTML = newT.split('').reduce((m, d) => {
        m += `<span>${d}</span>\n`
        return m
      }, '' as string)
    }
  }

  onChangeTime(t: number) {
    requestAnimationFrame(() => {
      this.updateTimeDisplay(t, this.$refs.currentTime as HTMLElement)
    })
  }

  onChangeSeeking(s: boolean) {
    this.seeking = s
    console.log('onChangeSeeking', s)
  }

  mounted() {
    console.log('this.transcript.uiState', this.transcript.uiState)
    bus.$on('updateTime', this.onChangeTime)
    bus.$on('scrubAudio', this.onChangeTime)
    bus.$on('updateSeeking', this.onChangeSeeking)
    this.onChangeTime(this.currentTime)
  }
}
